import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Typed from 'react-typed';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Components
import Layout from '../components/layout/Layout';
import EmailSignatureSimulatorSection from '../components/section/simulator/EmailSignature';
import EmailSignaturePlansSection from '../components/section/plans/EmailSignature';
import RealisationsSection from '../components/section/Realisations';
import TestimonialsSection from '../components/section/Testimonials';

// Images
import headerBackgroundImage from '../images/svg/components/wave-pattern-light.svg';
import shapeBackgroundImage from '../images/svg/components/shape-6.svg';

const EmailSignaturePage = ({ data }) => {

  const { t } = useTranslation();

  // Tawk Toggle
  const handleClickTawk = useCallback(
    e => {
      e.preventDefault();
      window.Tawk_API.toggle();
    },
    []
  );

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords')}
      headerClass="navbar-absolute-top navbar-dark"
      headerStyle="dark"
    >
      <div>
        <div className="position-relative bg-dark overflow-hidden" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }}>
          <div className="container position-relative zi-2 content-space-t-3 content-space-t-lg-4 content-space-b-2 content-space-b-md-3">
            <div className="row align-items-lg-center d-none d-md-flex" data-aos="fade-up">
              <div className="col-lg-5 mb-7 mb-lg-0">
                <div className="mb-6">
                  <h1 className="h1 display-6 text-white mb-3">{t('title')}
                    <span className="text-warning">
                      <Typed
                        strings={t('titleTyped').split(',')}
                        typeSpeed={40}
                        backSpeed={50}
                        loop >
                      </Typed>
                    </span>
                  </h1>
                  <p className="lead text-white-70">{t('subtitle')}</p>
                </div>
                <div className="d-grid d-sm-flex gap-3">
                  <Link className="btn btn-primary btn-transition" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="position-relative">
                  <StaticImage className="img-fluid" quality="30" src="../images/illustrations/email-signature/la-small-agency-email-signature.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                </div>
              </div>
            </div>
            <div className="row align-items-lg-center d-md-none d-flex" data-aos="fade-up">
              <div className="col-lg-5 mb-7 mb-lg-0">
                <div className="position-relative mb-3">
                  <StaticImage className="img-fluid" quality="30" src="../images/illustrations/email-signature/la-small-agency-email-signature.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                </div>
                <div className="mb-5">
                  <div className="h1 display-6 text-white mb-3">{t('title')}
                    <span className="text-warning">
                      <Typed
                        strings={t('titleTyped').split(',')}
                        typeSpeed={40}
                        backSpeed={50}
                        loop >
                      </Typed>
                    </span>
                  </div>
                  <p className="lead text-white-70">{t('subtitle')}</p>
                </div>
                <div className="d-grid d-sm-flex gap-3">
                  <Link className="btn btn-primary btn-transition" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-bottom zi-1">
            <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 500H3000V0L0 500Z" fill="#fff" />
            </svg>
          </div>
        </div>
        <div className="container content-space-t-2 content-space-b-md-2 content-space-t-lg-4">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <p className="text-cap">{t('benefits.pretitle')}</p>
            <h2 dangerouslySetInnerHTML={{ __html: t('benefits.title') }}></h2>
          </div>
          <div className="row gx-3">
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.identity.title')}</div>
                  <p className="card-text text-body">{t('benefits.identity.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.personalized.title')}</div>
                  <p className="card-text text-body">{t('benefits.personalized.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-sm-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.39961 20.5073C7.29961 20.5073 6.39961 19.6073 6.39961 18.5073C6.39961 17.4073 7.29961 16.5073 8.39961 16.5073H9.89961C11.7996 16.5073 13.3996 14.9073 13.3996 13.0073C13.3996 11.1073 11.7996 9.50732 9.89961 9.50732H8.09961L6.59961 11.2073C6.49961 11.3073 6.29961 11.4073 6.09961 11.5073C6.19961 11.5073 6.19961 11.5073 6.29961 11.5073H9.79961C10.5996 11.5073 11.2996 12.2073 11.2996 13.0073C11.2996 13.8073 10.5996 14.5073 9.79961 14.5073H8.39961C6.19961 14.5073 4.39961 16.3073 4.39961 18.5073C4.39961 20.7073 6.19961 22.5073 8.39961 22.5073H15.3996V20.5073H8.39961Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M8.89961 8.7073L6.69961 11.2073C6.29961 11.6073 5.59961 11.6073 5.19961 11.2073L2.99961 8.7073C2.19961 7.8073 1.7996 6.50732 2.0996 5.10732C2.3996 3.60732 3.5996 2.40732 5.0996 2.10732C7.6996 1.50732 9.99961 3.50734 9.99961 6.00734C9.89961 7.00734 9.49961 8.0073 8.89961 8.7073Z" fill="#035A4B"></path>
                      <path d="M5.89961 7.50732C6.72804 7.50732 7.39961 6.83575 7.39961 6.00732C7.39961 5.1789 6.72804 4.50732 5.89961 4.50732C5.07119 4.50732 4.39961 5.1789 4.39961 6.00732C4.39961 6.83575 5.07119 7.50732 5.89961 7.50732Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M17.3996 22.5073H15.3996V13.5073C15.3996 12.9073 15.7996 12.5073 16.3996 12.5073C16.9996 12.5073 17.3996 12.9073 17.3996 13.5073V22.5073Z" fill="#035A4B"></path>
                      <path d="M21.3996 18.5073H15.3996V13.5073H21.3996C22.1996 13.5073 22.5996 14.4073 22.0996 15.0073L21.2996 16.0073L22.0996 17.0073C22.6996 17.6073 22.1996 18.5073 21.3996 18.5073Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.attractiveness.title')}</div>
                  <p className="card-text text-body">{t('benefits.attractiveness.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M21 18.3V4H20H5C4.4 4 4 4.4 4 5V20C10.9 20 16.7 15.6 19 9.5V18.3C18.4 18.6 18 19.3 18 20C18 21.1 18.9 22 20 22C21.1 22 22 21.1 22 20C22 19.3 21.6 18.6 21 18.3Z" fill="#035A4B"></path>
                      <path d="M22 4C22 2.9 21.1 2 20 2C18.9 2 18 2.9 18 4C18 4.7 18.4 5.29995 18.9 5.69995C18.1 12.6 12.6 18.2 5.70001 18.9C5.30001 18.4 4.7 18 4 18C2.9 18 2 18.9 2 20C2 21.1 2.9 22 4 22C4.8 22 5.39999 21.6 5.79999 20.9C13.8 20.1 20.1 13.7 20.9 5.80005C21.6 5.40005 22 4.8 22 4Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.optimized.title')}</div>
                  <p className="card-text text-body">{t('benefits.optimized.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className="bg-light" id="checklist">
          <div className="content-space-2 content-space-lg-3">
            <div className="container w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title') }}></h2>
            </div>
            <div className="bg-img-start py-6" style={{ backgroundImage: 'url(' + shapeBackgroundImage + ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} >
              <div className="container w-lg-65 mx-lg-auto">
                <div className="card">
                  <div className="card-body">
                    <div className="row col-md-divider align-items-md-center">
                      <div className="col-md-9">
                        <h3>{t('checklist.card.title')}</h3>
                        <p>{t('checklist.card.subtitle')}</p>
                        <div className="row">
                          <div className="col-sm-6">
                            <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                              <li className="list-checked-item">{t('checklist.templates')}</li>
                              <li className="list-checked-item">{t('checklist.style')}</li>
                              <li className="list-checked-item">{t('checklist.details')}</li>
                              <li className="list-checked-item">{t('checklist.contacts')}</li>
                            </ul>
                          </div>
                          <div className="col-sm-6">
                            <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                              <li className="list-checked-item">{t('checklist.socialNetworks')}</li>
                              <li className="list-checked-item">{t('checklist.links')}</li>
                              <li className="list-checked-item">{t('checklist.cta')}</li>
                              <li className="list-checked-item">{t('checklist.images')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="ps-md-2">
                          <StaticImage className="img-fluid shadow rounded-2" quality="30" src="../images/illustrations/email-signature/templates/1.png" alt={t('plans.communityManagement.outlook')} />
                          <div className="mt-2" data-aos="fade-up">
                            <Link className="link" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('process.title') }}></h2>
          </div>
          <div className="row align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div>
                <div className="nav nav-sm nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3 row" role="tablist">
                  <a className="nav-item nav-link col-12 active" href="#process-conversation" id="process-conversation-tab" data-bs-toggle="tab" data-bs-target="#process-conversation" role="tab" aria-controls="process-conversation" aria-selected="true" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.conversation.title')}</div>
                        <p className="text-body mb-0">{t('process.conversation.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-design" id="process-design-tab" data-bs-toggle="tab" data-bs-target="#process-design" role="tab" aria-controls="process-design" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M6.45801 14.775L9.22501 17.542C11.1559 16.3304 12.9585 14.9255 14.605 13.349L10.651 9.39502C9.07452 11.0415 7.66962 12.8441 6.45801 14.775V14.775Z" fill="#035A4B"></path>
                          <path d="M9.19301 17.51C9.03401 19.936 6.76701 21.196 3.55701 21.935C3.34699 21.9838 3.12802 21.9782 2.92074 21.9189C2.71346 21.8596 2.52471 21.7484 2.37231 21.5959C2.2199 21.4434 2.10886 21.2545 2.04967 21.0472C1.99048 20.8399 1.98509 20.6209 2.034 20.411C2.772 17.201 4.03401 14.934 6.45801 14.775L9.19301 17.51ZM21.768 4.43697C21.9476 4.13006 22.0204 3.77232 21.9751 3.41963C21.9297 3.06694 21.7687 2.73919 21.5172 2.48775C21.2658 2.2363 20.9381 2.07524 20.5854 2.02986C20.2327 1.98449 19.8749 2.0574 19.568 2.23701C16.2817 4.20292 13.2827 6.61333 10.656 9.39998L14.61 13.354C17.395 10.7252 19.8037 7.72455 21.768 4.43697V4.43697Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.design.title')}</div>
                        <p className="text-body mb-0">{t('process.design.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-build" id="process-build-tab" data-bs-toggle="tab" data-bs-target="#process-build" role="tab" aria-controls="process-build" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2718 8.68537C16.8933 8.28319 16.9125 7.65032 17.3146 7.2718C17.7168 6.89329 18.3497 6.91246 18.7282 7.31464L22.7282 11.5646C23.0906 11.9497 23.0906 12.5503 22.7282 12.9354L18.7282 17.1854C18.3497 17.5875 17.7168 17.6067 17.3146 17.2282C16.9125 16.8497 16.8933 16.2168 17.2718 15.8146L20.6268 12.25L17.2718 8.68537Z" fill="#035A4B"></path>
                          <path d="M6.7282 8.68537C7.10671 8.28319 7.08754 7.65032 6.68536 7.2718C6.28319 6.89329 5.65031 6.91246 5.2718 7.31464L1.2718 11.5646C0.909397 11.9497 0.909397 12.5503 1.2718 12.9354L5.2718 17.1854C5.65031 17.5875 6.28319 17.6067 6.68536 17.2282C7.08754 16.8497 7.10671 16.2168 6.7282 15.8146L3.37325 12.25L6.7282 8.68537Z" fill="#035A4B"></path>
                          <rect opacity="0.3" x="12.7388" y="3.97168" width="2" height="16" rx="1" transform="rotate(12.3829 12.7388 3.97168)" fill="#035A4B"></rect>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.build.title')}</div>
                        <p className="text-body mb-0">{t('process.build.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-launch" id="process-launch-tab" data-bs-toggle="tab" data-bs-target="#process-launch" role="tab" aria-controls="process-launch" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="#035A4B"></path>
                          <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.install.title')}</div>
                        <p className="text-body mb-0">{t('process.install.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-follow-up" id="process-follow-up-tab" data-bs-toggle="tab" data-bs-target="#process-follow-up" role="tab" aria-controls="process-follow-up" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.followUp.title')}</div>
                        <p className="text-body mb-0">{t('process.followUp.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="process-conversation" role="tabpanel" aria-labelledby="processOne-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/email-signature/la-small-agency-email-signature-process-conversation.svg" alt={t('process.conversation.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.conversation.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-design" role="tabpanel" aria-labelledby="process-design-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/email-signature/la-small-agency-email-signature-process-design.svg" alt={t('process.design.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.design.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-build" role="tabpanel" aria-labelledby="process-build-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/email-signature/la-small-agency-email-signature-process-build.svg" alt={t('process.build.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.build.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-launch" role="tabpanel" aria-labelledby="process-launch-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/email-signature/la-small-agency-email-signature-process-launch.svg" alt={t('process.install.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.install.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-follow-up" role="tabpanel" aria-labelledby="process-follow-up-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/email-signature/la-small-agency-email-signature-process-follow-up.svg" alt={t('process.followUp.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.followUp.text')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mx-md-auto mt-2 mb-5 mb-md-9" data-aos="fade-up">
            <button className="btn btn-primary btn-transition" onClick={handleClickTawk}><i className="bi bi-chat me-1"></i>{t('cta.letsTalk')}</button>
          </div>
        </div>
        <div className="content-space-2 position-relative zi-2 overflow-hidden">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <span className="text-cap">{t('emailServices.title')}</span>
            <h2 dangerouslySetInnerHTML={{ __html: t('emailServices.subtitle') }}></h2>
          </div>
          <Swiper
            className="w-75 p-6"
            modules={[Navigation, A11y, Autoplay]}
            navigation={true}
            pagination={{
              dynamicBullets: false,
              clickable: true
            }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              "380": {
                "slidesPerView": 1,
                "spaceBetween": 15,
              },
              "580": {
                "slidesPerView": 2,
                "spaceBetween": 15,
              },
              "768": {
                "slidesPerView": 3,
                "spaceBetween": 15,
              },
              "1024": {
                "slidesPerView": 3,
                "spaceBetween": 30,
              }
            }}
          >
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#E03B2F' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/gmail-icon.svg" alt={t('emailServices.gmail.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('emailServices.gmail.title')}</div>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#0b71c9' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/outlook-icon.svg" alt={t('emailServices.outlook.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('emailServices.outlook.title')}</div>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#597AFF' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/apple-mail-icon.svg" alt={t('emailServices.appleMail.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('emailServices.appleMail.title')}</div>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#6518dd' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/yahoo-mail-icon.svg" alt={t('emailServices.yahooMail.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('emailServices.yahooMail.title')}</div>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="shape shape-bottom">
            <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 500H3000V0L0 500Z" fill="#f8fafd" />
            </svg>
          </div>
        </div>
        <div className="bg-light">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('simulator.title')}</h2>
              <p>{t('simulator.subtitle')}</p>
            </div>
            <EmailSignatureSimulatorSection data={data} plan={data.plan.frontmatter} service="email_signature" />
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('plans.title')}</h2>
            <p>{t('plans.subtitle')}</p>
          </div>
          <EmailSignaturePlansSection data={data} plan={data.plan.frontmatter} service="email_signature" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        {data.realisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('realisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('realisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <RealisationsSection data={data} realisations={data.realisations} service="home" />
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('realisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {data.testimonials.nodes.length ?
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('testimonials.title')}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="home" />
            <div className="text-center aos-init aos-animate" data-aos="fade-up">
              <div className="card card-info-link card-sm mb-5 mb-md-0">
                <div className="card-body">
                  {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="bg-light d-none d-md-block">
          <div className="container p-4 pb-md-0 pe-md-0 pt-md-10 ps-md-7">
            <div className="position-relative overflow-hidden">
              <div className="row justify-content-lg-between">
                <div className="col-md-4 py-5 pb-md-10">
                  <div className="mb-5">
                    <h2>{t('cta.title')}</h2>
                  </div>
                  <Link className="btn btn-outline-primary" to="/contact/"><i className="bi bi-envelope me-1"></i>{t('cta.contactUs')}</Link>
                </div>
                <div className="col-sm-6 col-lg-5">
                  <div className="position-absolute top-0 start-50">
                    <figure className="rotated-3d-left">
                      <StaticImage className="device-browser-img rounded-2" quality="30" src="../images/illustrations/email-signature/templates/1.png" alt={t('image.alt', { title: t('common.image.alt') })} width={400} />
                    </figure>
                  </div>
                  <div className="position-absolute top-0 start-50 mt-10 ms-10">
                    <figure className="rotated-3d-left">
                      <StaticImage className="img-fluid shadow rounded-2" quality="30" src="../images/illustrations/email-signature/templates/2.png" alt={t('image.alt', { title: t('common.image.alt') })} width={400} />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmailSignaturePage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "email-signature", "plans", "simulator-email-signature", "categories", "tools", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { services: { in: ["email-signature"] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { pages: {services: { in: ["email-signature"] } }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    plan: mdx(slug: {eq: "email_signature"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          persons {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          mockups {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          feedbacks {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          icons {
              active
              included
              active_price
          }
          profile_pictures {
              active
              included
              active_price
              additional_price
          }
          company_logo {
              active
              included
              active_price
          }
          setups {
              active
              included
              active_price
              additional_price
          }
          delivered {
              active
              create
              person
              mockup
              feedback
              icon
              profile_picture
              company_logo
              setup
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
  }
`;
